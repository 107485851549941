<template>
  <el-dialog
    :title="$t('order_handling.season_card_orders.process_order.title')"
    :visible.sync="dialogFormVisible"
    @close="close"
    id="process-season-card-order"
  >
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('order_handling.season_card_orders.process_order.alert_message')"></span>
    </el-alert>
    <div class="order-info">
      <el-scrollbar wrap-class="el-select-dropdown__wrap">
        <template v-for="field in orderData">
          <template>
            <base-section :title="field.title" noPadding :withBorder="false" :key="field.title">
              <base-button
                v-if="field.content"
                @click.stop="handleCopy(field.content)"
                size="small"
                prepend-icon="ri-file-copy-line"
              ></base-button>
              <span class="text-content">{{ field.content }}</span>
            </base-section>
          </template>
        </template>
      </el-scrollbar>
    </div>
    <el-form :key="dialogKey">
      <base-section
        noPadding
        :title="$t('order_handling.season_card_orders.process_order.effective_start_date')"
        :withBorder="false"
      >
        <base-datepicker v-model="form.startDate" />
      </base-section>
      <base-section
        noPadding
        :title="$t('order_handling.season_card_orders.process_order.effective_price')"
        :withBorder="false"
      >
        <base-currency-input v-model="form.price" :precision="2"></base-currency-input>
      </base-section>
      <base-section noPadding :title="$t('order_handling.season_card_orders.process_order.remark')" :withBorder="false">
        <base-input
          type="textarea"
          :autosize="{ minRows: 1, maxRows: 5 }"
          maxlength="140"
          v-model.trim="form.remark"
        ></base-input>
      </base-section>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <base-button
        :loading="loading"
        type="primary"
        :label="$t('order_handling.season_card_orders.process_order.success_message')"
        @click="handleConfirm"
      ></base-button>
    </span>
  </el-dialog>
</template>

<script>
import ProcessSeasonOrder from '@/mixins/ProcessSeasonOrder'

export default {
  name: 'ProcessOrder',
  mixins: [ProcessSeasonOrder],
  data () {
    return {}
  },
  computed: {
    orderData () {
      return [
        {
          title: this.$t('order_handling.season_card_orders.process_order.gender'),
          content: this.order.userGender
        },
        {
          title: this.$t('order_handling.season_card_orders.process_order.lastname'),
          content: this.order.userLastName
        },
        {
          title: this.$t('order_handling.season_card_orders.process_order.firstname'),
          content: this.order.userFirstName
        },
        {
          title: this.$t('order_handling.season_card_orders.process_order.street'),
          content: this.order.userHomeStreet
        },
        {
          title: this.$t('order_handling.season_card_orders.process_order.house_number'),
          content: this.order.userHomeStreetNumber
        },
        {
          title: this.$t('order_handling.season_card_orders.process_order.postal_code'),
          content: this.order.userHomeZipCode
        },
        {
          title: this.$t('order_handling.season_card_orders.process_order.city'),
          content: this.order.userHomeCity
        },
        {
          title: this.$t('order_handling.season_card_orders.process_order.country'),
          content: this.order.userHomeCountry
        },
        {
          title: this.$t('order_handling.season_card_orders.process_order.date_of_birth'),
          content: this.order.userBirthDate
        },
        {
          title: this.$t('order_handling.season_card_orders.process_order.language'),
          content: this.order.userLanguage
        },
        {
          title: this.$t('order_handling.season_card_orders.process_order.national_number'),
          content: this.order.userNationalNumber
        },
        {
          title: this.$t('order_handling.season_card_orders.process_order.personnel_number'),
          content: this.order.userEmployeeNumber
        },
        {
          title: this.$t('order_handling.season_card_orders.process_order.mobib_card_number'),
          content: this.order.mobibCardNumber
        },
        {
          title: this.$t('order_handling.season_card_orders.process_order.ordered_product'),
          content: this.order.productName
        },
        {
          title: this.$t('order_handling.season_card_orders.process_order.duration'),
          content: `${this.order.validityValue} ${this.order.validityUnit}`.toLowerCase()
        },
        {
          title: this.$t('order_handling.season_card_orders.process_order.start_date'),
          content: this.order.startDate
        },
        {
          title: this.$t('order_handling.season_card_orders.process_order.price'),
          content: this.order.price.priceVatIncl
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';

#process-season-card-order {
  .el-dialog {
    width: 600px;
    margin-top: 5vh !important;
  }
  .order-info {
    margin: 24px 0;
    padding: 12px 0;
    border-top: 2px solid $gray-400;
    border-bottom: 2px solid $gray-400;
  }
  .el-select-dropdown__wrap {
    max-height: 280px;
  }
  .prepend-icon {
    font-size: 1.3em;
  }
  .base-section {
    margin: 5px 0;
    min-height: 24px;
  }
  .base-section:nth-child(3),
  .base-section:nth-child(8),
  .base-section:nth-child(12) {
    margin-bottom: 34px;
  }
  .base-section__title {
    margin: auto 0;
    padding: 0;
    width: 180px;
  }
  .base-section__content {
    display: flex;
    .text-content {
      padding-left: 8px;
    }
    & > * {
      margin: auto 0;
    }
  }
  .el-alert__description {
    span {
      word-break: initial;
    }
  }
  .el-form {
    &-item {
      width: 250px;
    }
    .base-section {
      margin: 5px 0;
      min-height: 24px;
      &:last-child {
        .base-section__title {
          margin: 12px 0;
        }
      }
    }
    .el-textarea__inner {
      border-color: #b6bec9;
      color: #282c36;
    }
  }
}
</style>
