<template>
  <base-section :noPadding="true">
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('order_handling.handled_season_card_orders.alert_text')"></span>
    </el-alert>
    <el-table
      id="handledOrdersTable"
      ref="table"
      :data.sync="tableData"
      :empty-text="$t('general.empty_table')"
      row-class-name="table-row"
      style="width: 100%"
    >
      <el-table-column prop="icon" width="37">
        <template>
          <i class="ri-contacts-line"></i>
        </template>
      </el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="userFullName"
        :label="$t('general.label.user')"
        min-width="180"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="{ row }">
          <a href="#" @click.prevent="showUserDetails(row)">{{ row.userFullName }}</a>
        </template>
      </el-table-column>
      <el-table-column
        prop="mrpName"
        :label="$t('general.label.service')"
        :show-overflow-tooltip="true"
        min-width="110"
      >
      </el-table-column>
      <el-table-column
        prop="productName"
        :label="$t('general.label.product_name')"
        min-width="170"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="requestedOnDate"
        :label="$t('general.label.requested_on')"
        :show-overflow-tooltip="true"
        min-width="110"
      >
      </el-table-column>
      <el-table-column
        prop="orderStatus"
        :label="$t('general.label.order_status')"
        :show-overflow-tooltip="true"
        min-width="110"
      >
      </el-table-column>
    </el-table>
  </base-section>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { DATE_FORMAT } from '@/app/helpers'
import { debounce } from 'lodash'
import TableLoader from '@/mixins/TableLoader'
import CancelRequest from '@/mixins/CancelRequest'

const defaultSort = () => ({
  sortOrders: [{ direction: 'DESCENDING', property: 'requestedOn' }]
})

export default {
  name: 'HandledOrders',
  mixins: [TableLoader, CancelRequest],
  props: {
    totalPages: {
      type: Number,
      default: 0
    },
    pagination: {
      type: Object,
      default: () => ({
        page: 0,
        size: 15,
        sort: defaultSort()
      })
    }
  },
  data () {
    return {
      handledOrders: []
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    tableData () {
      return this.handledOrders.map(el => ({
        ...el,
        userFullName: `${el.userFirstName} ${el.userLastName}`,
        requestedOnDate: DateTime.fromISO(el.requestedOn).toFormat(DATE_FORMAT)
      }))
    }
  },
  methods: {
    async fetchHandledOrders () {
      const loading = this.TableLoader_show()
      const filter = {
        corporateAccountOspId: this.corporateAccount.ospId,
        pagination: { ...this.pagination, sort: defaultSort() }
      }
      const options = {
        onSuccess: res => {
          this.setData(res)
        },
        onFinally: () => {
          loading.close()
        },
        cancelToken: this.CancelRequest_token()
      }
      try {
        await this.$api.seasonTicketOrder.getHandledOrders(filter, options)
      } catch (e) {
        console.error(e)
      }
    },
    showUserDetails (data) {
      const loading = this.TableLoader_show()
      this.$router.push(
        {
          name: 'AppUserDetailView',
          params: {
            userOspId: data.userOspId
          },
          query: {
            corporateAccountOspId: this.corporateAccount.ospId
          }
        },
        () => this.$sleep(50).then(loading.close),
        () => this.$sleep(50).then(loading.close)
      )
    },
    setData ({ content, totalPages }) {
      this.handledOrders = content
      this.$emit('update:totalPages', totalPages)
    }
  },
  watch: {
    'pagination.page': debounce(function () {
      this.fetchHandledOrders()
    }, 200)
  },
  mounted () {
    this.fetchHandledOrders()
  }
}
</script>

<style lang="scss"></style>
