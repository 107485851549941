<template>
  <page-layout :title="$t('order_handling.title')" :sub-title="subtitle" :fixed-footer="!!totalPages && totalPages > 1">
    <el-tabs :value="activeName" ref="tabs">
      <el-tab-pane v-for="tab in tabs" :key="tab.name" :name="tab.name">
        <span slot="label" class="flex-span">
          <i v-if="tab.icon" :class="tab.icon"></i>
          {{ tab.label }}
        </span>
      </el-tab-pane>
    </el-tabs>
    <router-view ref="routerView" :key="$route.name" :totalPages.sync="totalPages" :pagination.sync="pagination" />
    <template #footer v-if="totalPages && totalPages > 1">
      <base-pagination :key="'pagination_' + $route.name" v-model="pagination.page" :pages="totalPages" />
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import TabNavigation from '../mixins/TabNavigation'

export default {
  name: 'OrderHandlingLayout',
  components: {
    PageLayout
  },
  mixins: [TabNavigation],
  props: {
    emitBack: {
      typ: Boolean,
      default: false
    }
  },
  data () {
    return {
      key: '',
      activeName: '',
      totalPages: 0,
      pagination: {
        page: 0,
        size: 15,
        sort: {}
      }
    }
  },
  methods: {},
  watch: {
    '$route.name' () {
      this.totalPages = 0
      this.pagination.page = 0
    }
  },
  computed: {
    tabs () {
      return [
        {
          label: this.$t('order_handling.tabs.season_card_orders'),
          name: 'SeasonCardOrders',
          icon: 'ri-building-4-line'
        },
        {
          label: this.$t('order_handling.tabs.handled_season_card_orders'),
          name: 'HandledSeasonCardOrders',
          icon: 'ri-check-line'
        }
      ]
    },
    subtitle () {
      switch (this.$route.name) {
        case 'SeasonCardOrders':
          return this.$t('order_handling.tabs.season_card_orders').toUpperCase()
        case 'HandledSeasonCardOrders':
          return this.$t('order_handling.tabs.handled_season_card_orders').toUpperCase()
      }
      return ''
    }
  }
}
</script>

<style lang="scss"></style>
