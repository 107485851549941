<template>
  <div>
    <base-section :noPadding="true">
      <el-alert type="info" :closable="false" show-icon>
        <span v-html="$t('order_handling.season_card_orders.alert_text')"></span>
      </el-alert>
      <el-table
        id="ordersTable"
        ref="table"
        :data.sync="tableData"
        :empty-text="$t('general.empty_table')"
        row-class-name="table-row"
        @sort-change="sortChange"
        style="width: 100%"
      >
        <el-table-column prop="icon" width="37">
          <template>
            <i class="ri-contacts-line"></i>
          </template>
        </el-table-column>
        <el-table-column
          class-name="text-bold"
          prop="userFullName"
          :label="$t('general.label.user')"
          min-width="180"
          :show-overflow-tooltip="true"
          sortable="sortColumn"
        >
          <template slot-scope="{ row }">
            <a href="#" @click.prevent="showUserDetails(row)">{{ row.userFullName }}</a>
          </template>
        </el-table-column>
        <el-table-column
          prop="mrpName"
          :label="$t('general.label.service')"
          :show-overflow-tooltip="true"
          min-width="110"
          sortable="sortColumn"
        >
        </el-table-column>
        <el-table-column
          prop="productName"
          :label="$t('general.label.product_name')"
          min-width="170"
          :show-overflow-tooltip="true"
          sortable="sortColumn"
        >
        </el-table-column>
        <el-table-column
          prop="requestedOnDate"
          :label="$t('general.label.requested_on')"
          :show-overflow-tooltip="true"
          min-width="130"
          sortable="sortColumn"
        >
        </el-table-column>
        <el-table-column class-name="actions-cell" prop="buttons" :label="''" width="150">
          <template slot-scope="{ row }">
            <base-button
              @click.stop="handleProcess(row)"
              :label="$t('general.label.process')"
              type="primary"
              size="small"
            ></base-button>
            <base-button
              @click.stop="handleCancel(row)"
              size="small"
              prepend-icon="ri-delete-bin-2-line"
            ></base-button>
          </template>
        </el-table-column>
      </el-table>
    </base-section>
    <cancel-order ref="cancelOrder" @confirm="confirm"></cancel-order>
    <process-order ref="processOrder" @confirm="confirm"></process-order>
    <process-nmbs-order ref="processNmbsOrder" @confirm="confirm"></process-nmbs-order>
    <process-mivb-order ref="processMivbOrder" @confirm="confirm"></process-mivb-order>
    <process-de-lijn-order ref="processDeLijnOrder" @confirm="confirm"></process-de-lijn-order>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { orderBy } from 'lodash'
import { DateTime } from 'luxon'
import { DATE_FORMAT, PROVIDERS } from '@/app/helpers'
import TableLoader from '@/mixins/TableLoader'
import CancelOrder from '@/components/OrderHandling/SeasonCardOrders/CancelOrder'
import ProcessOrder from '@/components/OrderHandling/SeasonCardOrders/ProcessOrder'
import ProcessNmbsOrder from '@/components/OrderHandling/SeasonCardOrders/ProcessNmbsOrder'
import ProcessMivbOrder from '@/components/OrderHandling/SeasonCardOrders/ProcessMivbOrder'
import ProcessDeLijnOrder from '@/components/OrderHandling/SeasonCardOrders/ProcessDeLijnOrder'

const defaultSort = () => ({
  order: 'ascending',
  prop: 'userFullName'
})

export default {
  name: 'SeasonCardOrders',
  components: { CancelOrder, ProcessOrder, ProcessNmbsOrder, ProcessDeLijnOrder, ProcessMivbOrder },
  mixins: [TableLoader],
  data () {
    return {
      orders: [],
      sort: defaultSort()
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    tableData () {
      const prop = this.sort.prop
      const order = this.sort.order === 'descending' ? 'desc' : 'asc'
      return orderBy(
        this.orders,
        v => {
          try {
            return v[prop].toLowerCase()
          } catch (e) {
            return v[prop]
          }
        },
        order
      )
    }
  },
  methods: {
    async fetchData () {
      const loading = this.TableLoader_show()
      const filter = { corporateAccountOspId: this.corporateAccount.ospId }
      const options = {
        onSuccess: res => {
          this.setData(res)
        },
        onFinally: () => {
          loading.close()
        }
      }
      try {
        await this.$api.seasonTicketOrder.getOpenOrders(filter, options)
      } catch (e) {
        console.error(e)
      }
    },
    setData (data) {
      this.orders = data.map(el => ({
        ...el,
        userFullName: `${el.userFirstName} ${el.userLastName}`,
        requestedOnDate: DateTime.fromISO(el.requestedOn).toFormat(DATE_FORMAT)
      }))
    },
    handleCancel (data) {
      this.$refs.cancelOrder.show(data)
    },
    handleProcess (data) {
      switch (data.mrpOspId) {
        case PROVIDERS.NMBS:
          return this.$refs.processNmbsOrder.show(data)
        case PROVIDERS.DELIJN:
          return this.$refs.processDeLijnOrder.show(data)
        case PROVIDERS.MIVB:
          return this.$refs.processMivbOrder.show(data)
        default:
          return this.$refs.processOrder.show(data)
      }
    },
    showUserDetails (data) {
      const loading = this.TableLoader_show()
      this.$router.push(
        {
          name: 'AppUserDetailView',
          params: {
            userOspId: data.userOspId
          },
          query: {
            corporateAccountOspId: this.corporateAccount.ospId
          }
        },
        () => this.$sleep(50).then(loading.close),
        () => this.$sleep(50).then(loading.close)
      )
    },
    sortChange (sort) {
      this.sort = sort
    },
    async confirm ({ form, event }) {
      const successNotification =
        event === 'confirmOrder'
          ? this.$t('order_handling.season_card_orders.confirmed_message')
          : this.$t('order_handling.season_card_orders.cancelled_message')
      const loading = this.TableLoader_show()
      const options = {
        successNotification,
        onSuccess: () => {
          this.fetchData()
        },
        onFinally: () => {
          loading.close()
        }
      }
      try {
        await this.$api.seasonTicketOrder[event](form, options)
      } catch (e) {
        console.error(e)
      }
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style scoped></style>
